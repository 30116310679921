<template>

  <b-card>
    <div slot="header">
      <strong>Listar Usuarios de Grupos</strong>
    </div>
    
    <div>
      <router-link :to="{ name: 'Criar Usuarios de Grupos', params: { id: this.$route.params.id } }" class="btn btn-primary">Gerenciar Usuarios de Grupo</router-link></p>
    </div>

    <table class="table">
      <thead>
        <tr>
          <th>Usuário</th>
          <th>Grupo</th>
          <th style="width: 140px;">Ações</th>
        </tr>
      </thead>
      <tbody>
        <tr v-for="usuarioGrupoItem in usuariosGrupos">
          <td> {{ usuarioGrupoItem.usuario.name }} </td>
          <td> {{ usuarioGrupoItem.grupo.mnemonico }} </td>
          <td>
            <b-btn :to="{ name: 'Remover Usuarios de Grupos', params: { id: usuarioGrupoItem.id } }" variant="danger" size="sm">Excluir</b-btn>
          </td>
        </tr>
      </tbody>
    </table>

  </b-card>
  
</template>

<script>
export default {
  data () {
    return {
      usuariosGrupos: []
    }
  },
  created: function () {
    this.verificaSessao()
    this.usuariosGrupos = []
  },
  methods: {
    verificaSessao: function () {
      if (!this.$session.exists()) { this.$session.start() }
      this.$http.get(this.URLApi + 'user/session', {
        headers: {
          'X-DreamFactory-Session-Token': this.$session.get('jwt'),
          'X-Dreamfactory-API-Key': this.ChaveApi
        }
      }).then((response) => {
        this.regraListar()
      }, (response) => {
        var m = response.body.error.code
        if (m === 401) {
          this.$notify({
            type: 'error',
            title: 'Sessão expirada',
            text: 'Seu tempo de logado expirou.'
          })
        }
        this.$router.push('/login')
      })
    },
    regraListar: function () {
      this.listar('filtro_User_Grupo', 'name', '(gtipo_ID = 1)').then(
        (response) => {
          for (var i = 0; i < response.length; i++) {
            this.usuariosGrupos.push({
              grupo: { mnemonico: response[i].grp_mnemonico },
              usuario: { name: response[i].name },
              id: response[i].ugrp_ID
            })
          }
        }
      )
    }
  }
}
</script>

<style>

</style>
